'use client';

import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import styles from './styles.module.scss';

const BannerContact: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        setErrorMessage(null);

        const formData = new FormData(event.currentTarget);

        try {
            const response = await fetch('https://www.rdstation.com.br/api/1.3/conversions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 904297ddf27aa488461f1936e94791d6'
                },
                body: JSON.stringify({
                    token_rdstation: '904297ddf27aa488461f1936e94791d6',
                    identificador: 'form-eventos-parceiros',
                    nome: formData.get('nome'),
                    email: formData.get('email'),
                    telefone: formData.get('telefone'),
                })
            });

            if (response.ok) {
                setIsSubmitted(true);
                setIsSubmitting(false);
            } else {
                setErrorMessage('Ocorreu um erro. Tente novamente.');
                setIsSubmitting(false);
            }
        } catch (error) {
            setErrorMessage('Ocorreu um erro. Tente novamente.');
            setIsSubmitting(false);
        }
    };

    return (
        <section id='cadastro' className={styles.bannerSection} style={{ backgroundImage: 'url("../images-capa/banner/background-mobile.webp")' }}>
            <div className={styles.container}>
                <div className={styles.rowBanner}>
                    <div className={styles.boxBanner}>
                        <img
                            src='images-capa/banner/star.svg'
                            title='Estrela'
                            alt='Estrela'
                            width='100%'
                            height='auto'
                            className={styles.imgHeader}
                        />
                        <h1>Seja um Parceiro Promo e lucre realizando sonhos!</h1>
                        <p>Comece ainda hoje a sua parceria com o <span>melhor e mais completo</span> programa de afiliados do setor de turismo!</p>
                        <p><span>Divulgue</span> diversas marcas e receba <span>comissionamento</span> por toda venda feita em seu link!</p>
                    </div>

                    <div className={styles.boxBanner}>
                        <form id="form-eventos-parceiros" method='POST' onSubmit={handleSubmit}>
                            <div className={styles.oneInputRow}>
                                <div className={styles.boxInputForm}>
                                    <img
                                        src='images-capa/form/userForm.svg'
                                        title='Nome'
                                        alt='Nome'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgFormInput}
                                    />
                                    <input name='nome' id='nome' type='text' placeholder='Nome' required />
                                </div>
                            </div>

                            <div className={styles.oneInputRow}>
                                <div className={styles.boxInputForm}>
                                    <img
                                        src='images-capa/form/phone.svg'
                                        title='Telefone'
                                        alt='Telefone'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgFormInput}
                                    />
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        name='telefone'
                                        id='telefone'
                                        type='text'
                                        placeholder='Telefone'
                                        required
                                    />
                                </div>
                            </div>

                            <div className={styles.oneInputRow}>
                                <div className={styles.boxInputForm}>
                                    <img
                                        src='images-capa/form/email.svg'
                                        title='E-mail'
                                        alt='E-mail'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgFormInput}
                                    />
                                    <input name='email' id='email' type='email' placeholder='E-mail' required />
                                </div>
                            </div>

                            <div>
                                <button
                                    type='submit'
                                    className={styles.submitButton}
                                    disabled={isSubmitting || isSubmitted}
                                >
                                    {isSubmitting ? 'Enviando...' : isSubmitted ? 'Enviado!' : 'Quero me tornar um Parceiro!'}
                                </button>
                            </div>

                            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerContact;
