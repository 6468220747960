import React from 'react';
import styles from './styles.module.scss';

const Footer: React.FC = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.footerSection}>
            <div className={styles.container}>

                <div className={styles.rowFooter}>

                    <div className={styles.boxFooter}>
                        <ul>
                            <a href='/signin/'>
                                <li>Acesse sua conta</li>
                            </a>
                            <a href='https://www.parceirospromo.com.br/static/img/termo_de_uso___parceirospromo___21122023.pdf' target='_blank'>
                                <li>Termos de Adesão</li>
                            </a>
                        </ul>
                    </div>

                    <div className={styles.boxFooter}>
                        <img
                            src='images-capa/global/logo.svg'
                            title='Parceiros Promo'
                            alt='Parceiros Promo'
                            width='100%'
                            height='auto'
                            className={styles.imgFooter}
                        />
                    </div>

                    <div className={styles.boxFooter}>
                        <ul>
                            <a href='https://www.parceirospromo.com.br/static/img/pol%C3%ADtica_de_privacidade___parceiros_promo___04072023.pdf' target='_blank'>
                                <li>Políticas de Uso</li>
                            </a>
                        </ul>

                        <div className={styles.socialImages}>
                            <a href='https://www.facebook.com/profile.php?id=100091227527664' target='_blank'>
                                <img
                                    src='images-capa/footer/facebook.svg'
                                    title='Facebook'
                                    alt='Facebook'
                                    width='100%'
                                    height='auto'
                                    className={styles.imgFooterSocial}
                                />
                            </a>

                            <a href='https://www.instagram.com/parceirospromo/' target='_blank'>
                                <img
                                    src='images-capa/footer/instagram.svg'
                                    title='Instagram'
                                    alt='Instagram'
                                    width='100%'
                                    height='auto'
                                    className={styles.imgFooterSocial}
                                />
                            </a>

                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.rowCopy}>
                <div className={styles.container}>
                    <p>Amo Promo | CNPJ: 73.690.653/0001-13 | Rua dos Timbiras, nº 2352, 6º andar - Belo Horizonte - MG © {currentYear} Parceiros Promo | By - Amopromo</p>
                </div>
            </div>
        </footer>


    );
};

export default Footer;