import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

const Header: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 1300 && window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <header className={isFixed ? styles.fixedHeader : ''}>
      <div className={styles.container}>
        <div className={styles.rowHeader}>
          <div>
            <picture>
              <source
                media="(max-width: 1300px)"
                srcSet="images-capa/global/logo-mobile.svg"
              />
              <img
                src="images-capa/global/logo.svg"
                title="Parceiros Promo"
                alt="Parceiros Promo"
                width="100%"
                height="auto"
                className={styles.imgHeader}
              />
            </picture>
          </div>

          <div className={styles.boxMenuDesktop}>
            <ul>
              <li>
                <a href="#marcas">Marcas Parceiras</a>
              </li>
              <li>
                <a href="#parceiros">Para quem é</a>
              </li>
              <li>
                <a href="#como-funciona">Como funciona</a>
              </li>
              <li>
                <a href="#vantagens">Vantagens</a>
              </li>
            </ul>
          </div>

          <div className={styles.buttonHeader}>
            <a href="/signup/">
              <button className={styles.buttonHeaderParceiro}>Ser um parceiro</button>
            </a>
            <a href="/signin/">
              <button className={styles.buttonHeaderLogin}>Fazer login</button>
            </a>
          </div>

          <div className={styles.MenuMobileIcon} onClick={toggleSidebar}>
            <img
              src='images-capa/global/bar.svg'
              title='Menu'
              alt='Menu'
              width='100%'
              height='auto'
              className={styles.imgHeaderMenu}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ''}`}>
        <div className={styles.headerSidebar}>
          <img
            srcSet="images-capa/global/logo-mobile.svg"
            title='Parceiros Promo'
            alt='Parceiros Promo'
            width='100%'
            height='auto'
            className={styles.imgHeaderMenuLogo}
          />
          <img
            src='images-capa/global/close.svg'
            title='Fechar'
            alt='Fechar'
            width='100%'
            height='auto'
            className={styles.imgHeaderMenuBar}
            onClick={toggleSidebar}
          />
        </div>

        <div className={styles.bodySidebar}>
          <a href="/signin/">
            <button className={styles.buttonHeaderLogin}>Fazer login</button>
          </a>
        </div>

        <ul>
          <li><a href="#marcas" onClick={closeSidebar}>Marcas Parceiras</a></li>
          <li><a href="#parceiros" onClick={closeSidebar}>Para quem é</a></li>
          <li><a href="#como-funciona" onClick={closeSidebar}>Como funciona</a></li>
          <li><a href="#vantagens" onClick={closeSidebar}>Vantagens</a></li>
        </ul>
      </div>

      <div
        className={`${styles.overlay} ${isSidebarOpen ? styles.show : ''}`}
        onClick={toggleSidebar}
      ></div>
    </header>
  );
};

export default Header;
