import React from 'react'

import HeaderSimple from '../../components/HeaderSimple';
import Footer from '../../components/Footer';
import BannerContact from '../../components/BannerContact';
import SliderProduto from '../../components/SliderProduto';
import TabProduto from '../../components/TabProduto';
import Dados from '../../components/Dados';
import Publico from '../../components/Publico';
import Faq from '../../components/Faq';
import ComoFunciona from '../../components/ComoFunciona';
import Vantagens from '../../components/Vantagens';
import Influencer from '../../components/Influencer';

const Eventos: React.FC = () => {
  return (
    <div>
      <HeaderSimple />
      <BannerContact />
      <SliderProduto />
      <TabProduto />
      <Dados />
      <Publico signupLink='#cadastro' />
      <Faq />
      <ComoFunciona signupLink='#cadastro' />
      <Vantagens signupLink='#cadastro' />
      <Influencer signupLink='https://docs.google.com/forms/d/e/1FAIpQLScpRisoq2i52xwxR1xe12AVcgYbWRrSqHE3_aywaU8s0eXFZw/viewform?gxids=7628' />
      <Footer />
    </div>
  );
};

export default Eventos;