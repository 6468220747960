import React from 'react';
import styles from './styles.module.scss';

const BannerContact: React.FC = () => {
    return (
        <section className={styles.bannerSection}
            style={{
                backgroundImage: window.matchMedia('(max-width: 1300px)').matches
                    ? 'url("../images-capa/banner/background-mobile.webp")'
                    : 'url("../images-capa/banner/background-mobile.webp")'
            }}>
            <div className={styles.container}>

                <div className={styles.rowBanner}>

                    <div className={styles.boxBanner}>
                        <img
                            src='images-capa/banner/star.svg'
                            title='Estrela'
                            alt='Estrela'
                            width='100%'
                            height='auto'
                            className={styles.imgHeader}
                        />
                        <h1>Seja um Parceiro Promo e lucre realizando sonhos!</h1>
                        <p>Comece ainda hoje a sua parceria com o <span>melhor e mais completo</span> programa de afiliados do setor de turismo!</p>
                        <p><span>Divulgue</span> diversas marcas e receba <span>comissionamento</span> por toda venda feita em seu link!</p>

                        <a href='/signup/' title='Quero ser um Parceiro Promo!'>
                            <button>Quero ser um Parceiro Promo!</button>
                        </a>
                    </div>

                    <div className={styles.boxBanner}>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/Vo0ndeHO_QU?si=AjQR7iTwmyiprCUt"
                            title="YouTube video player"
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default BannerContact;