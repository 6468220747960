import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './styles.module.scss';
import '../../styles/slider.css';

const Dados: React.FC = () => {
    const boxesRef = useRef<HTMLDivElement[]>([]);

    return (
        <section className={styles.dadosSection} style={{ backgroundImage: 'url("../images-capa/global/background.webp")' }}>
            <div className={styles.container}>

                {/* Slider para dispositivos móveis */}
                <div className={styles.sliderContainerDados}>
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={30}
                        slidesPerView={2}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            800: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            500: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                                centeredSlides: true,
                            },
                        }}
                        className={`${styles.customSliderDados} sliderDados`}
                    >
                        <SwiperSlide>
                            <div className={`${styles.box1Dados} ${styles.column2Box1Dados}`}>
                                <img
                                    src='images-capa/dados/person.svg'
                                    title='+ de 9 mil parceiros'
                                    alt='+ de 9 mil parceiros'
                                    width='100%'
                                    height='auto'
                                    className={styles.iconRow1}
                                />
                                <h3>+ de 9 mil parceiros</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${styles.box1Dados} ${styles.column2Box2Dados}`}>
                                <img
                                    src='images-capa/dados/money.svg'
                                    title='+ de R$50 milhões distribuídos em comissão'
                                    alt='+ de R$50 milhões distribuídos em comissão'
                                    width='100%'
                                    height='auto'
                                    className={styles.iconRow1}
                                />
                                <h3>+ de R$50 milhões distribuídos em comissão</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${styles.box1Dados} ${styles.column2Box3Dados}`}>
                                <img
                                    src='images-capa/dados/dados.svg'
                                    title='Relatórios Personalizados'
                                    alt='Relatórios Personalizados'
                                    width='100%'
                                    height='auto'
                                    className={styles.iconRow1}
                                />
                                <h3>Relatórios Personalizados</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${styles.box1Dados} ${styles.column2Box2Dados}`}>
                                <img
                                    src='images-capa/dados/whatsapp.svg'
                                    title='Suporte por e-mail e whatsapp'
                                    alt='Suporte por e-mail e whatsapp'
                                    width='100%'
                                    height='auto'
                                    className={styles.iconRow1}
                                />
                                <h3>Suporte por e-mail e whatsapp</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${styles.box1Dados} ${styles.column2Box1Dados}`}>
                                <img
                                    src='images-capa/dados/clock.svg'
                                    title='Visão em tempo real'
                                    alt='Visão em tempo real'
                                    width='100%'
                                    height='auto'
                                    className={styles.iconRow1}
                                />
                                <h3>Visão em tempo real</h3>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* Versão desktop */}
                <div className={styles.row1Dados}>
                    <div className={`${styles.box1Dados} ${styles.column2Box1Dados}`}>
                        <img
                            src='images-capa/dados/person.svg'
                            title='+ de 9 mil parceiros'
                            alt='+ de 9 mil parceiros'
                            width='100%'
                            height='auto'
                            className={styles.iconRow1}
                        />
                        <h3>+ de 9 mil parceiros</h3>
                    </div>
                    <div className={`${styles.box1Dados} ${styles.column2Box2Dados}`}>
                        <img
                            src='images-capa/dados/money.svg'
                            title='+ de R$50 milhões distribuídos em comissão'
                            alt='+ de R$50 milhões distribuídos em comissão'
                            width='100%'
                            height='auto'
                            className={styles.iconRow1}
                        />
                        <h3>+ de R$50 milhões distribuídos em comissão</h3>
                    </div>
                    <div className={`${styles.box1Dados} ${styles.column2Box3Dados}`}>
                        <img
                            src='images-capa/dados/dados.svg'
                            title='Relatórios Personalizados'
                            alt='Relatórios Personalizados'
                            width='100%'
                            height='auto'
                            className={styles.iconRow1}
                        />
                        <h3>Relatórios Personalizados</h3>
                    </div>
                    <div className={`${styles.box1Dados} ${styles.column2Box2Dados}`}>
                        <img
                            src='images-capa/dados/whatsapp.svg'
                            title='Suporte por e-mail e whatsapp'
                            alt='Suporte por e-mail e whatsapp'
                            width='100%'
                            height='auto'
                            className={styles.iconRow1}
                        />
                        <h3>Suporte por e-mail e whatsapp</h3>
                    </div>
                    <div className={`${styles.box1Dados} ${styles.column2Box1Dados}`}>
                        <img
                            src='images-capa/dados/clock.svg'
                            title='Visão em tempo real'
                            alt='Visão em tempo real'
                            width='100%'
                            height='auto'
                            className={styles.iconRow1}
                        />
                        <h3>Visão em tempo real</h3>
                    </div>
                </div>

                <div className={styles.row2Dados}>
                    <div className={styles.box2Dados}>
                        <img
                            src='images-capa/dados/pc.webp'
                            title='Sistema Parceiros Promo'
                            alt='Sistema Parceiros Promo'
                            width='100%'
                            height='auto'
                            className={styles.iconRow2}
                        />
                    </div>
                </div>

                <div className={styles.row3Dados}>
                    <img
                        src='images-capa/dados/grafic2.webp'
                        title='Gráfico'
                        alt='Gráfico'
                        width='100%'
                        height='auto'
                        className={styles.icon1Row3}
                    />
                    <img
                        src='images-capa/dados/fly.svg'
                        title='Avião'
                        alt='Avião'
                        width='100%'
                        height='auto'
                        className={styles.icon2Row3}
                    />
                    <img
                        src='images-capa/dados/grafic.webp'
                        title='Gráfico'
                        alt='Gráfico'
                        width='100%'
                        height='auto'
                        className={styles.icon3Row3}
                    />
                    <img
                        src='images-capa/dados/chip.svg'
                        title='Chip'
                        alt='Chip'
                        width='100%'
                        height='auto'
                        className={styles.icon4Row3}
                    />
                    <img
                        src='images-capa/dados/work.svg'
                        title='Prédio'
                        alt='Prédio'
                        width='100%'
                        height='auto'
                        className={styles.icon5Row3}
                    />
                    <img
                        src='images-capa/dados/value1.webp'
                        title='Valor em vendas'
                        alt='Valor em vendas'
                        width='100%'
                        height='auto'
                        className={styles.icon6Row3}
                    />
                    <img
                        src='images-capa/dados/value.webp'
                        title='Valor ao mês'
                        alt='Valor ao mês'
                        width='100%'
                        height='auto'
                        className={styles.icon7Row3}
                    />
                </div>
            </div>
        </section>
    );
};

export default Dados;
