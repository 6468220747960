import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './styles.module.scss';
import '../../styles/slider.css';

const SliderProduto: React.FC = () => {
    return (
        <section id="marcas" className={styles.SliderProdutoSection}>
            <div className={styles.container}>
                <h2>Conheça nossos produtos!</h2>
                <Swiper
                    modules={[Pagination, Autoplay]}
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    slidesPerView={4}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        1300: {
                            slidesPerView: 4,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        320: {
                            slidesPerView: 2,
                        },
                    }}
                    className={styles.customSlider}
                >
                    <SwiperSlide>
                        <img
                            src='images-capa/produto/seguros-promo.svg'
                            title='Seguros Promo'
                            alt='Seguros Promo'
                            width='100%'
                            height='auto'
                            className={styles.imgSlider}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src='images-capa/produto/vaidepromo.svg'
                            title='Vai de Promo'
                            alt='Vai de Promo'
                            width='100%'
                            height='auto'
                            className={styles.imgSlider}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src='images-capa/produto/viaje-conectado.svg'
                            title='Vaije Conectado'
                            alt='Vaije Conectado'
                            width='100%'
                            height='auto'
                            className={styles.imgSlider}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src='images-capa/produto/mobility.svg'
                            title='Mobility'
                            alt='Mobility'
                            width='100%'
                            height='auto'
                            className={styles.imgSlider}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src='images-capa/produto/getMalas.svg'
                            title='Get Malas'
                            alt='Get Malas'
                            width='100%'
                            height='auto'
                            className={styles.imgSlider}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src='images-capa/produto/fluencypass.svg'
                            title='Fluency Pass'
                            alt='Fluency Pass'
                            width='100%'
                            height='auto'
                            className={styles.imgSlider}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
};

export default SliderProduto;
