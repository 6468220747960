import React, { useState } from 'react';
import styles from './styles.module.scss';

const HeaderSimple: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header>
      <div className={styles.container}>
        <div className={styles.rowHeader}>

          <div>
            <picture>
              <source
                media="(max-width: 1300px)"
                srcSet="images-capa/global/logo-mobile.svg"
              />
              <img
                src="images-capa/global/logo.svg"
                title="Parceiros Promo"
                alt="Parceiros Promo"
                width="100%"
                height="auto"
                className={styles.imgHeader}
              />
            </picture>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderSimple;
