import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Banner from '../../components/Banner';
import SliderProduto from '../../components/SliderProduto';
import TabProduto from '../../components/TabProduto';
import Dados from '../../components/Dados';
import Publico from '../../components/Publico';
import Faq from '../../components/Faq';
import ComoFunciona from '../../components/ComoFunciona';
import Vantagens from '../../components/Vantagens';
import Influencer from '../../components/Influencer';

const Capa: React.FC = () => {
  return (
    <div>
      <Header />
      <Banner />
      <SliderProduto />
      <TabProduto />
      <Dados />
      <Publico signupLink='/signup/'/>
      <Faq />
      <ComoFunciona signupLink='/signup/'/>
      <Vantagens signupLink='/signup/'/>
      <Influencer signupLink='https://docs.google.com/forms/d/e/1FAIpQLScpRisoq2i52xwxR1xe12AVcgYbWRrSqHE3_aywaU8s0eXFZw/viewform?gxids=7628'/>
      <Footer />
    </div>
  );
};

export default Capa;