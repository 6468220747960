import React from 'react';
import styles from './styles.module.scss';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const NotFound: React.FC = () => {
  return (
    <div>
      <Header />
      <div className={styles.notFound}>
        <div className={styles.container}>
          <h1>Ops!</h1>
          <p>Página não encontrada.</p>
          <a href="/"><button>Voltar para a página inicial</button></a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;