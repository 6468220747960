import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

const TabProduto: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const tabs = [
        {
            id: 0,
            title: 'images-capa/produto/seguros-promo.svg',
            service: 'Seguro viagem',
            about: 'Comparador de seguros de viagem das principais seguradoras do país! Oferecemos os melhores preços da internet, o melhor atendimento e a segurança de ter um Seguro de Viagem.'
        },
        {
            id: 1,
            title: 'images-capa/produto/vaidepromo.svg',
            service: 'Passagens aéreas e hotelaria',
            about: 'O Vai de Promo é a solução para quem deseja organizar, planejar e comprar uma viagem, tudo em um único lugar. A plataforma reúne as principais seguradoras do país e as maiores companhias aéreas para, com o melhor preço do mercado, transformar sonhos de viagem em realidade.'
        },
        {
            id: 2,
            title: 'images-capa/produto/viaje-conectado.svg',
            service: 'Chip internacional',
            about: 'Presente em mais de 100 países. É a revendedora autorizada das melhores operadoras de telefonia dos EUA e do mundo. Com mais de 40 pontos de retirada de chip no Brasil e no exterior, facilidade e comodidade são os pontos fortes deste serviço.'
        }
    ];

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800); // Definindo o ponto de quebra como 800px
        };

        handleResize(); // Verificar ao carregar o componente
        window.addEventListener('resize', handleResize); // Adicionar listener para redimensionamento

        return () => window.removeEventListener('resize', handleResize); // Limpar listener ao desmontar o componente
    }, []);

    return (
        <section className={styles.tabSection}>
            <div className={styles.containerPerso}>
                {isMobile ? (
                    tabs.map((tab) => (
                        <div key={tab.id} className={styles.mobileTab}>
                            <button
                                className={`${styles.tabHeader} ${activeTab === tab.id ? styles.active : ''}`}
                                onClick={() => handleTabClick(tab.id)}
                            >
                                <img src={tab.title} alt={`Tab ${tab.id}`} className={styles.tabImage} />
                            </button>
                            {activeTab === tab.id && (
                                <div className={styles.tabContent}>
                                    <label>Produtos e serviços</label>
                                    <p>{tab.service}</p>
                                    <label>Sobre a marca</label>
                                    <p>{tab.about}</p>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <>
                        <div className={styles.tabHeaders}>
                            {tabs.map((tab) => (
                                <button
                                    key={tab.id}
                                    className={`${styles.tabHeader} ${activeTab === tab.id ? styles.active : ''}`}
                                    onClick={() => handleTabClick(tab.id)}
                                >
                                    <img src={tab.title} alt={`Tab ${tab.id}`} className={styles.tabImage} />
                                </button>
                            ))}
                        </div>
                        <div className={styles.tabContent}>
                            <label>Produtos e serviços</label>
                            <p>{tabs[activeTab].service}</p>
                            <label>Sobre a marca</label>
                            <p>{tabs[activeTab].about}</p>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default TabProduto;
