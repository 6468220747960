import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../styles/slider.css';
import { Pagination } from 'swiper/modules';
import styles from './styles.module.scss';

interface PublicoProps {
    signupLink: string;
}


const Publico: React.FC<PublicoProps> = ({ signupLink }) => {
    const boxesRef = useRef<HTMLDivElement[]>([]);

    return (
        <section id="parceiros" className={styles.publicoSection}>
            <div className={styles.container}>
                <h2>Para quem é o Parceiros Promo?</h2>

                {/* Swiper para dispositivos móveis */}
                <div className={styles.sliderContainerPublico}>
                    <Swiper
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={60}
                        slidesPerView={1}
                        breakpoints={{
                            800: {
                                slidesPerView: 2,
                            },
                            500: {
                                slidesPerView: 1,
                            },
                            320: {
                                slidesPerView: 1,
                                centeredSlides: false,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                                <div className={styles.boxPublico}>
                                    <div>
                                        <h3>Influenciadores</h3>
                                        <p>É um influencer e deseja divulgar nossas marcas para ganhar comissão com suas vendas? O Parceiros Promo é para você!</p>
                                    </div>
                                    <img
                                        src='images-capa/publico/arrow.svg'
                                        title='Arrow'
                                        alt='Arrow'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgProduto}
                                    />
                                </div>
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                                <div className={styles.boxPublico}>
                                    <div>
                                        <h3>Blogueiros</h3>
                                        <p>Disponibilize nossas campanhas em seu blog, gerando cliques que vão se converter em vendas e comissão para o seu bolso!</p>
                                    </div>
                                    <img
                                        src='images-capa/publico/arrow.svg'
                                        title='Arrow'
                                        alt='Arrow'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgProduto}
                                    />
                                </div>
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                                <div className={styles.boxPublico}>
                                    <div>
                                        <h3>Agentes de Viagem</h3>
                                        <p>Divulgue nossas marcas para os seus clientes como um agente de viagem e seja comissionado por toda venda!</p>
                                    </div>
                                    <img
                                        src='images-capa/publico/arrow.svg'
                                        title='Arrow'
                                        alt='Arrow'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgProduto}
                                    />
                                </div>
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                                <div className={styles.boxPublico}>
                                    <div>
                                        <h3>Profissionais de Marketing Digital</h3>
                                        <p>Leve tráfego para nossas marcas, receba comissão por cada venda feita em seu link!</p>
                                    </div>
                                    <img
                                        src='images-capa/publico/arrow.svg'
                                        title='Arrow'
                                        alt='Arrow'
                                        width='100%'
                                        height='auto'
                                        className={styles.imgProduto}
                                    />
                                </div>
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* Versão desktop */}
                <div className={styles.rowPublico}>
                    <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                        <div className={styles.boxPublico}>
                            <div>
                                <h3>Influenciadores</h3>
                                <p>É um influencer e deseja divulgar nossas marcas para ganhar comissão com suas vendas? O Parceiros Promo é para você!</p>
                            </div>
                            <img
                                src='images-capa/publico/arrow.svg'
                                title='Arrow'
                                alt='Arrow'
                                width='100%'
                                height='auto'
                                className={styles.imgProduto}
                            />
                        </div>
                    </a>
                    <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                        <div className={styles.boxPublico}>
                            <div>
                                <h3>Blogueiros</h3>
                                <p>Disponibilize nossas campanhas em seu blog, gerando cliques que vão se converter em vendas e comissão para o seu bolso!</p>
                            </div>
                            <img
                                src='images-capa/publico/arrow.svg'
                                title='Arrow'
                                alt='Arrow'
                                width='100%'
                                height='auto'
                                className={styles.imgProduto}
                            />
                        </div>
                    </a>
                    <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                        <div className={styles.boxPublico}>
                            <div>
                                <h3>Agentes de Viagem</h3>
                                <p>Divulgue nossas marcas para os seus clientes como um agente de viagem e seja comissionado por toda venda!</p>
                            </div>
                            <img
                                src='images-capa/publico/arrow.svg'
                                title='Arrow'
                                alt='Arrow'
                                width='100%'
                                height='auto'
                                className={styles.imgProduto}
                            />
                        </div>
                    </a>
                    <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                        <div className={styles.boxPublico}>
                            <div>
                                <h3>Profissionais de Marketing Digital</h3>
                                <p>Leve tráfego para nossas marcas, receba comissão por cada venda feita em seu link!</p>
                            </div>
                            <img
                                src='images-capa/publico/arrow.svg'
                                title='Arrow'
                                alt='Arrow'
                                width='100%'
                                height='auto'
                                className={styles.imgProduto}
                            />
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Publico;
