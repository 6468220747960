import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import styles from './styles.module.scss';

const Faq: React.FC = () => {
    const boxesRef = useRef<HTMLDivElement[]>([]);

    return (
        <section className={styles.faqSection} style={{ backgroundImage: 'url("../images-capa/global/background.webp")' }}>
            <div className={styles.container}>
                <h2>O que falam do Parceiros Promo?</h2>

                {/* Swiper para dispositivos móveis */}
                <div className={styles.sliderContainerFaq}>
                    <Swiper
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={15}
                        slidesPerView={1}
                        breakpoints={{
                            800: {
                                slidesPerView: 2,
                            },
                            500: {
                                slidesPerView: 1,
                            },
                            320: {
                                slidesPerView: 1,
                                centeredSlides: false,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className={`${styles.boxFaq} ${styles.column2Box1Faq}`}>
                                <div className={styles.faqHeader}>
                                    <div>
                                        <img
                                            src='images-capa/faq/fernanda.webp'
                                            title='Fernanda Firmato'
                                            alt='Fernanda Firmato'
                                            width='100%'
                                            height='auto'
                                            className={styles.imgFaq}
                                        />
                                    </div>
                                    <div>
                                        <h3>Fernanda Firmato</h3>
                                        <h4>Libretd</h4>
                                    </div>
                                </div>
                                <p>“Trabalhar com o Parceiros Promo tem sido muito gratificante! Além de ter produtos diversificados, os ganhos são muito positivos, a plataforma é amigável e me sinto sempre amparada pelo suporte da marca. É algo que confio e indico justamente por sentir que sempre tem gente disposta a me auxiliar no que eu preciso. E é um serviço necessário que nunca vi outra marca no mercado oferecendo de forma tão completa. Pretendo continuar com essa parceria por mais longos anos! ❤️”</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className={`${styles.boxFaq} ${styles.column2Box1Faq}`}>
                                <div className={styles.faqHeader}>
                                    <div>
                                        <img
                                            src='images-capa/faq/matheus.webp'
                                            title='Matheus Petruceli'
                                            alt='Matheus Petruceli'
                                            width='100%'
                                            height='auto'
                                            className={styles.imgFaq}
                                        />
                                    </div>
                                    <div>
                                        <h3>Matheus Petruceli</h3>
                                        <h4>Petruceli</h4>
                                    </div>
                                </div>
                                <p>“O contato direto com o time e a ferramenta incrível para gestão dos resultados, com certeza são grandes diferenciais do Parceiros Promo. Parceiros desde o início do projeto e que agregam muito pela troca de conhecimentos. Uma verdadeira parceria!”</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className={`${styles.boxFaq} ${styles.column2Box1Faq}`}>
                                <div className={styles.faqHeader}>
                                    <div>
                                        <img
                                            src='images-capa/faq/eurodicas.webp'
                                            title='Equipe Euro Dicas'
                                            alt='Equipe Euro Dicas'
                                            width='100%'
                                            height='auto'
                                            className={styles.imgFaq}
                                        />
                                    </div>
                                    <div>
                                        <h3>Equipe Euro Dicas</h3>
                                        <h4>Euro Dicas</h4>
                                    </div>
                                </div>
                                <p>"Ser um afiliado do Parceiros Promo tem sido uma experiência excepcional para o Euro Dicas. Esta parceria tem sido fundamental para nosso sucesso, temos toda a confiança no trabalho da Parceiros e, com o suporte contínuo oferecido, a parceria impulsionou significativamente nosso crescimento. Estamos muito gratos por essa aliança e ansiosos para continuar prosperando juntos."</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className={`${styles.boxFaq} ${styles.column2Box1Faq}`}>
                                <div className={styles.faqHeader}>
                                    <div>
                                        <img
                                            src='images-capa/faq/fabinho.webp'
                                            title='Fabinho'
                                            alt='Fabinho'
                                            width='100%'
                                            height='auto'
                                            className={styles.imgFaq}
                                        />
                                    </div>
                                    <div>
                                        <h3>Fabinho</h3>
                                        <h4>Dicas Nova York</h4>
                                    </div>
                                </div>
                                <p>“O Dicas Nova York está junto com o Parceiros Promo desde 2017. De lá para cá, essa troca nos trouxe muito aprendizado e resultado em vendas. Trabalhar com o Parceiros Promo é ter tranquilidade para ajudar os leitores com produtos de viagem e ter uma parceria para todas as horas. Construímos uma amizade ao longo dos anos e somos muito felizes em trabalhar e indicar o Parceiros Promo para todos os produtores de conteúdo! Esperamos que nossa união continue por muito mais tempo.”</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* Versão desktop */}
                <div className={styles.rowFaq}>

                    <div className={`${styles.boxFaq} ${styles.column2Box1Faq}`}>

                        <div className={styles.faqHeader}>

                            <div>
                                <img
                                    src='images-capa/faq/fernanda.webp'
                                    title='Fernanda Firmato'
                                    alt='Fernanda Firmato'
                                    width='100%'
                                    height='auto'
                                    className={styles.imgFaq}
                                />
                            </div>

                            <div>
                                <h3>Fernanda Firmato</h3>
                                <h4>Libretd</h4>
                            </div>

                        </div>

                        <p>“Trabalhar com o Parceiros Promo tem sido muito gratificante! Além de ter produtos diversificados, os ganhos são muito positivos, a plataforma é amigável e me sinto sempre amparada pelo suporte da marca. É algo que confio e indico justamente por sentir que sempre tem gente disposta a me auxiliar no que eu preciso. E é um serviço necessário que nunca vi outra marca no mercado oferecendo de forma tão completa. Pretendo continuar com essa parceria por mais longos anos! ❤️”</p>

                    </div>

                    <div className={`${styles.boxFaq} ${styles.column2Box2Faq}`}>

                        <div className={styles.faqHeader}>

                            <div>
                                <img
                                    src='images-capa/faq/matheus.webp'
                                    title='Matheus Petruceli'
                                    alt='Matheus Petruceli'
                                    width='100%'
                                    height='auto'
                                    className={styles.imgFaq}
                                />
                            </div>

                            <div>
                                <h3>Matheus Petruceli</h3>
                                <h4>Petruceli</h4>
                            </div>

                        </div>

                        <p>“O contato direto com o time e a ferramenta incrível para gestão dos resultados, com certeza são grandes diferenciais do Parceiros Promo. Parceiros desde o início do projeto e que agregam muito pela troca de conhecimentos. Uma verdadeira parceria!”</p>

                    </div>

                    <div className={`${styles.boxFaq} ${styles.column2Box1Faq}`}>

                        <div className={styles.faqHeader}>

                            <div>
                                <img
                                    src='images-capa/faq/eurodicas.webp'
                                    title='Equipe Euro Dicas'
                                    alt='Equipe Euro Dicas'
                                    width='100%'
                                    height='auto'
                                    className={styles.imgFaq}
                                />
                            </div>

                            <div>
                                <h3>Equipe Euro Dicas</h3>
                                <h4>Euro Dicas</h4>
                            </div>

                        </div>

                        <p>"Ser um afiliado do Parceiros Promo tem sido uma experiência excepcional para o Euro Dicas. Esta parceria tem sido fundamental para nosso sucesso, temos toda a confiança no trabalho da Parceiros e, com o suporte contínuo oferecido, a parceria impulsionou significativamente nosso crescimento. Estamos muito gratos por essa aliança e ansiosos para continuar prosperando juntos."</p>

                    </div>

                    <div className={`${styles.boxFaq} ${styles.column2Box2Faq}`}>

                        <div className={styles.faqHeader}>

                            <div>
                                <img
                                    src='images-capa/faq/fabinho.webp'
                                    title='Fabinho'
                                    alt='Fabinho'
                                    width='100%'
                                    height='auto'
                                    className={styles.imgFaq}
                                />
                            </div>

                            <div>
                                <h3>Fabinho</h3>
                                <h4>Dicas Nova York</h4>
                            </div>

                        </div>

                        <p>“O Dicas Nova York está junto com o Parceiros Promo desde 2017. De lá para cá, essa troca nos trouxe muito aprendizado e resultado em vendas. Trabalhar com o Parceiros Promo é ter tranquilidade para ajudar os leitores com produtos de viagem e ter uma parceria para todas as horas. Construímos uma amizade ao longo dos anos e somos muito felizes em trabalhar e indicar o Parceiros Promo para todos os produtores de conteúdo! Esperamos que nossa união continue por muito mais tempo.”</p>

                    </div >

                </div >

                <img
                    src='images-capa/faq/aspas1.svg'
                    title='Aspas'
                    alt='Aspas'
                    width='100%'
                    height='auto'
                    className={styles.imgFaqApas1}
                />

                <img
                    src='images-capa/faq/aspas2.svg'
                    title='Aspas'
                    alt='Aspas'
                    width='100%'
                    height='auto'
                    className={styles.imgFaqApas2}
                />

            </div >
        </section >
    );
};

export default Faq;
