import React from 'react';
import styles from './styles.module.scss';

interface InfluencerProps {
    signupLink: string;
}

const Influencer: React.FC<InfluencerProps> = ({ signupLink }) => {
    return (
        <section className={styles.influencerSection}>
            <div className={styles.containerPerso}>

                <div className={styles.rowInfluencer}>

                    <div className={styles.boxInfluencer}>

                        <h2>Quer ser um influencer das nossas marcas?</h2>
                        <p>Quer divulgar nossas marcas em suas redes? Cadastre-se no formulário exclusivo para influenciadores e nosso time de especialistas entrará em contato com você!</p>
                        <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                            <button>Quero ser um influenciador!</button>
                        </a>

                    </div>

                    <div className={styles.boxInfluencer}>
                        <img
                            src='images-capa/influencer/influencer.webp'
                            title='Quer ser um influencer das nossas marcas?'
                            alt='Quer ser um influencer das nossas marcas?'
                            width='100%'
                            height='auto'
                            className={styles.imgInfluenciador}
                        />
                    </div>

                </div>

            </div>
        </section>
    );
};

export default Influencer;