import React from 'react';
import styles from './styles.module.scss';

interface ComoFuncionaProps {
    signupLink: string;
}

const ComoFunciona: React.FC<ComoFuncionaProps> = ({ signupLink }) => {
    return (
        <section id="como-funciona" className={styles.comoFuncionaSection}>
            <div className={styles.container}>

                <div className={styles.rowComoFunc}>

                    <div className={styles.boxComoFunc}>

                        <h2>Como  funciona?</h2>

                        <img
                            src='images-capa/comoFunciona/como-funciona.webp'
                            title='Como  funciona?'
                            alt='Como  funciona?'
                            width='100%'
                            height='auto'
                            className={styles.imgComoFunc}
                        />

                    </div>

                    <div className={styles.boxComoFunc}>

                        <ul className={styles.lista}>
                            <li>
                                <p className={styles.numero}>1</p>
                                <div>
                                    <p className={styles.numeroTitle}>Cadastre-se gratuitamente!</p>
                                    <p className={styles.numeroDescription}>Basta efetuar o seu cadastro <a href={signupLink} title='Quero ser um Parceiro Promo!'><b>clicando aqui!</b></a></p>
                                </div>
                            </li>
                            <li>
                                <p className={styles.numero}>2</p>
                                <div>
                                    <p className={styles.numeroTitle}>Divulgue Muito!</p>
                                    <p className={styles.numeroDescription}>Divulgue as marcas, serviços e produtos parceiros com o seu link exclusivo de afiliado!</p>
                                </div>
                            </li>
                            <li>
                                <p className={styles.numero}>3</p>
                                <div>
                                    <p className={styles.numeroTitle}>Receba comissão por toda venda!</p>
                                    <p className={styles.numeroDescription}>Você receberá comissão para toda venda feita em seu link</p>
                                </div>
                            </li>
                            <li>
                                <p className={styles.numero}>4</p>
                                <div>
                                    <p className={styles.numeroTitle}>Acompanhe seus resultados</p>
                                    <p className={styles.numeroDescription}>Acompanhe em nossos relatórios personalizados e em tempo real todos os acessos, vendas e outros dados importantes</p>
                                </div>
                            </li>
                            <li>
                                <p className={styles.numero}>5</p>
                                <div>
                                    <p className={styles.numeroTitle}>Aprenda muito</p>
                                    <p className={styles.numeroDescription}>Disponibilizamos conteúdos e aulas exclusivas para aumentar a sua performance com o Parceiros Promo!</p>
                                </div>
                            </li>
                        </ul>

                        <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                            <button>Quero me tornar um Parceiro!</button>
                        </a>
                    </div>

                </div>

            </div>
        </section >
    );
};

export default ComoFunciona;