import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';

import Capa from './app/capa/page';
import Eventos from './app/eventos/page';
import NotFound from './app/404/page';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Capa />} />
          <Route path="/Eventos" element={<Eventos />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;