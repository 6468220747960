import React from 'react';
import styles from './styles.module.scss';

interface VantagensProps {
    signupLink: string;
}

const Vantagens: React.FC<VantagensProps> = ({ signupLink }) => {
    return (
        <section id="vantagens" className={styles.vantagensSection}>
            <div className={styles.container}>

                <h2>Vantagens de fazer parte do programa</h2>

                <div className={styles.rowVantagens}>

                    <div className={styles.boxVantagens}>
                        <img
                            src='images-capa/vantagens/transparencia.svg'
                            title='Transparência'
                            alt='Transparência'
                            width='100%'
                            height='auto'
                            className={styles.imgVantagens}
                        />

                        <h3>Transparência</h3>
                        <p>Acompanhe os acessos e vendas geradas através do seu link de afiliado</p>
                    </div>

                    <div className={styles.boxVantagens}>
                        <img
                            src='images-capa/vantagens/comissionamento.svg'
                            title='Comissionamento'
                            alt='Comissionamento'
                            width='100%'
                            height='auto'
                            className={styles.imgVantagens}
                        />

                        <h3>Comissionamento</h3>
                        <p>Receba comissionamento por todas as compras feitas através do seu link</p>
                    </div>

                    <div className={styles.boxVantagens}>
                        <img
                            src='images-capa/vantagens/materiais.svg'
                            title='Materiais Exclusivos'
                            alt='Materiais Exclusivos'
                            width='100%'
                            height='auto'
                            className={styles.imgVantagens}
                        />

                        <h3>Materiais Exclusivos</h3>
                        <p>Tenha acesso a banners, imagens e diversos materiais para trabalhar sua estratégia de divulgação e vendas</p>
                    </div>

                    <div className={styles.boxVantagens}>
                        <img
                            src='images-capa/vantagens/suporte.svg'
                            title='Suporte'
                            alt='Suporte'
                            width='100%'
                            height='auto'
                            className={styles.imgVantagens}
                        />

                        <h3>Suporte</h3>
                        <p>Está com dúvidas? Conte com a gente! Nossa equipe pode te auxiliar por WhatsApp e email.</p>
                    </div>

                </div>

                <div className={styles.boxVantagensButton}>
                    <a href={signupLink} title='Quero ser um Parceiro Promo!'>
                        <button>Faça parte!</button>
                    </a>
                </div>

            </div>
        </section>
    );
};

export default Vantagens;